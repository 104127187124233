<template>
  <div class="prizeDetail">
    <!-- <van-swipe  class="top-slider" vertical autoplay="3000" :show-indicators="false">
      <van-swipe-item v-for="(item,index) in successList" :key="index">{{item}}</van-swipe-item>
    </van-swipe> -->
    <!-- <div class="btn-fixed" v-if="isInvitee" @click="getInviteeDisk"></div> -->
    <!-- <div class="aside">
      <button class="btn-mini" @click="getDisk(prizeInfo.PrizeId)">{{prizeInfo.IsTacked?'已领取':'免费领取'}}</button>
      <div class="tip">邀请1名工程师：{{engineerList.length==0?0:1}}/1</div>
    </div> -->
    <!-- <div class="sec2">
      <button class="btn-mini" v-for="(item,index) in prizeList" :key="index" :disabled="item.IsTacked" @click="getPrize(item.PrizeId)">{{item.IsTacked?'已领取':'免费领取'}}</button>
    </div> -->
    <div class="sec3">
      <div class="btn-mini" @click="share">立即邀请</div>
      <!-- <span class="btn-text" @click="isShowRule=true">活动规则</span> -->
    </div>
    <div class="sec4">
      <div class="tab-hd">
        <div class="tab-hd-item">未完成：{{unfinishNum}}</div>
        <div class="tab-hd-item on">总邀请：{{total}}</div>
        <div class="tab-bg on"></div>
      </div>
      <div class="title">已完成认证工程师</div>
      <ul class="list">
        <li v-for="(item,index) in engineerList" :key="index">
          <span class="name">{{item.NickName}}</span>
          <span class="phone">{{item.HandSet}}</span>
        </li>
      </ul>
      <div class="title">已完成注册工程师</div>
      <ul class="list">
        <li v-for="(item,index) in personList" :key="index">
          <span class="name">{{item.NickName}}</span>
          <span class="phone">{{item.HandSet}}</span>
        </li>
      </ul>
      <!-- <div class="tips">最终的活动奖励以好友完成认证为标准哦！</div> -->
    </div>
    <div class="btn-block" @click="share">立即邀请</div>
    <!-- <van-popup class="address-dialog" v-model="isShowAddress">
      <div class="head">
        <h3 class="title">收货地址</h3>
        <van-icon  class="icon-close" name="cross" @click="isShowAddress=false" />
      </div>
      <div class="form">
        <div class="form-item">
          <input type="text" class="input" v-model.trim="form.LinkMan" placeholder="收货人（必填）">
        </div>
        <div class="form-item">
          <input type="tel" class="input" v-model.trim="form.HandSet" placeholder="联系方式+（必填）">
        </div>
        <div class="form-item">
          <input type="text" class="input" v-model.trim="form.Address" placeholder="所在地（必填）">
        </div>
        <div class="form-item">
          <input type="text" class="input" v-model.trim="form.DetailAddress" placeholder="详细地址（必填）">
        </div>
        <button class="btn-primary" @click="submitAddress">确定</button>
      </div>
    </van-popup> -->
    <!-- <van-popup class="rule-dialog" v-model="isShowRule">
      <div class="rule-inner">
        <h2 class="title">活动规则</h2>
        <van-icon  class="icon-close" name="cross" @click="isShowRule=false" />
        <article class="article">
          <p>1，新用户需要完成实名认证和至少一项工程师能力认证，才算认证成功。</p>
          <p>2，被邀请的新用户认证成功即可领取U盘。</p>
          <p>3，未领取过U盘的用户，邀请新用户完成认证即可领取U盘。</p>
          <p>4，每邀请3名新用户完成认证即可领取一套课程（共2套）。</p>
          <p>5，邀请更多好友完成认证，速派小梦会找你送福利哦。</p>
          <h3>活动时间:6月5日-6月30日</h3>
          <h3>奖品发放</h3>
          <p>1，U盘会在领取后三个工作日内发货（包邮）。</p>
          <p>2，课程会在领取当下自动添加到【我的课程】。</p>
          <p>3，其他实物奖励均在领取后三个工作日内发货（包邮）。</p>
          <p class="tips">本活动最终解释权归工业速派所有</p>
        </article>
      </div>
    </van-popup> -->
    <div class="wx-mask" v-if="isShowWX"></div>
  </div>
</template>
<script>
let u = navigator.userAgent;
let ua = navigator.userAgent.toLowerCase();
const isWebview = require("js-is-webview");
const is_webview = new isWebview();
let userAgent = {
    userAgent:navigator.userAgent
}
import {
  GetInviteEngineerActivityVO,
  AcceptPrize,
  AddShippingAddress,
  InvitedUserAcceptPrize,
} from "@/common/api.js";
import bridge from '@/common/dsbridge.js';
export default {
  data(){
    return{
      isShowAddress:false,
      isShowRule:false,
      successList:[],
      form:{
        LinkMan:'',
        HandSet:'',
        Address:'',
        DetailAddress:'',
      },
      userId:0,
      userType:1,
      total:0,
      unfinishNum:0,
      prizeInfo:{},//U盘
      prizeList:[],//其他奖品
      hasAddress:false,//是否填过地址？
      isInvitee:false,//被邀请人是否可领取
      engineerList:[],
      personList:[],
      isAPP:false,
      timer:null,
      isShowWX:false,
    }
  },
  created() {
    // this.userId=Number(this.$route.query.userId) || 0;
    // this.userType=Number(this.$route.query.userType) || 1;
      this.getUserInfo();
    this.isAPP=bridge.call('getUserInfo')===undefined?false:true;
  },
  mounted() {
      let that=this;
    bridge.register('notifyLoginSuccess', function() {
      that.getUserInfo();
    });
  },
  methods: {
    //未登录
    callLogin(){
      bridge.call('launchLogin');
    },
    openAPP: function() {
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.isShowWX=true;
        return;
      }
			if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
				console.log('安卓');
				let limit = 50;
        let t1 = +new Date();
        window.location.href = `gongkongsupai://com.gongkong.supai?launchType=9&pageTitle=邀请工程师&url=${window.location.origin}?path=prizeDetail&interaction=true`;//
        // let timer = setInterval(function() {
        //   if (limit > 0) {
        //     limit--;
        //   } else {
        //     if (new Date() - t1 < 700) {
              
        //     }
        //     clearTimeout(timer);
        //   }
        // }, 10);
        clearTimeout(this.timer);
        this.timer=setTimeout(() => {
          window.location.href = 'https://wx.insupai.com/download/download.html';
          clearTimeout(this.timer);
        }, 3000);
			} else if (u.indexOf('iPhone') > -1) {
				console.log('苹果');
				let limit = 100;
        let t1 = +new Date();
        window.location.href = `gongkongsupai://param?launchType=9&pageTitle=邀请工程师&url=${window.location.origin}?path=prizeDetail&interaction=true`;//
        // let timer = setInterval(function() {
        //   if (limit > 0) {
        //     limit--;
        //   } else {
        //     if (new Date() - t1 < 2200) {
        //       window.location.href = 'https://m.insupai.com/download/download';
        //     }
        //     clearTimeout(timer);
        //   }
        // }, 20);
        clearTimeout(this.timer);
        this.timer=setTimeout(() => {
          window.location.href = 'https://wx.insupai.com/download/download.html';
          clearTimeout(this.timer);
        }, 3000);
			}
		},
    //获取用户信息
    getUserInfo(){
      let that=this;
      let info=bridge.call('getUserInfo') ||'{}';
      let getUserInfoApp = JSON.parse(info);
      let userCode= getUserInfoApp.userId;
      if(!getUserInfoApp.isLogin){
        // that.callLogin();
        return;
      }
      if(userCode.indexOf('p')===0){
        that.userType=1;
        that.userId=userCode.substr(1);
      }else if(userCode.indexOf('e')===0){
        that.userType=2;
        that.userId=userCode.substr(1);
      }
      that.pageInit();
    },
    pageInit(){
      //获取页面数据
      GetInviteEngineerActivityVO({
        UserId:this.userId,
        UserType:this.userType,
      }).then(r=>{
        if(r.Result==1){
          this.successList=r.Data.ScrollList;
          this.total=r.Data.InviteInfo.TotalInviteCount;
          this.unfinishNum=r.Data.InviteInfo.UnFinishCount;
          this.engineerList=r.Data.InviteInfo.EngineerList || [];
          this.personList=r.Data.InviteInfo.PersonList || [];
          let list=r.Data.PrizeList||[];
          //课程
          this.prizeList=list.filter((item)=>{
            return item.PrizeId!=1;
          });
          let disk=list.filter((item)=>{
            return item.PrizeId==1;
          });
          this.prizeInfo=disk.length?disk[0] : {};//U盘
          this.hasAddress=r.Data.IsAddShippingAddress;//填写过地址
          this.isInvitee=r.Data.IsCanTackUsbFlashDisk;//被邀请人是否可领取
        }else{
          this.$toast(r.Message);
        }
      })
    },
    //被邀请人领取U盘
    getInviteeDisk(){
      if(!this.userId){
        this.callLogin();
      }
      if(this.prizeInfo.IsTacked){
        this.$toast('您已经领过');
        return;
      }
      if(this.hasAddress){
        InvitedUserAcceptPrize({
          UserId:this.userId,
          UserType:this.userType,
          PrizeId:this.prizeInfo.PrizeId,
        }).then(r=>{
          this.$toast(r.Message);
          if(r.Result==1){
            this.pageInit();
          }
        })
      }else{
        this.isShowAddress=true;
        this.form={
          LinkMan:'',
          HandSet:'',
          Address:'',
          DetailAddress:'',
          isInvited:true,
        }
      }
    },
    //领U盘
    getDisk(id){
      if(!this.isAPP){
        this.openAPP();
        return;
      }
      if(!this.userId){
        this.callLogin();
      }
      if(this.prizeInfo.IsTacked){
        this.$toast('您已经领过');
        return;
      }
      if(this.engineerList.length==0){
        this.$toast('请邀请工程师');
        return;
      }
      if(this.hasAddress){
        this.getPrize(id);
      }else{
        this.isShowAddress=true;
        this.form={
          LinkMan:'',
          HandSet:'',
          Address:'',
          DetailAddress:'',
        }
      }
    },
    // 领取礼物
    getPrize(id){
      AcceptPrize({
        UserId:this.userId,
        UserType:this.userType,
        PrizeId:id,
      }).then(r=>{
        if(r.Result==1){
          this.pageInit();
          if(id!=1){
            this.$dialog.alert({
              message: '领取成功，请到【我的课程】中查看',
            });
          }else{
            this.$toast(r.Message);
          }
        }else{
          this.$toast(r.Message);
        }
      })
    },
    //添加收货地址
    submitAddress(){
      if(this.form.LinkMan==''){
        this.$toast('请输入收货人');
        return;
      }
      if(this.form.HandSet==''){
        this.$toast('请输入收货人手机号');
        return;
      }
      if(this.form.Address==''){
        this.$toast('请输入所在城市');
        return;
      }
      if(this.form.DetailAddress==''){
        this.$toast('请输入详细地址');
        return;
      }
      AddShippingAddress({
        ...this.form,
        UserId:this.userId,
        UserType:this.userType,
      }).then(r=>{
        if(r.Result==1){
          this.isShowAddress=false;
          if(this.form.isInvited){
            InvitedUserAcceptPrize({
              UserId:this.userId,
              UserType:this.userType,
              PrizeId:this.prizeInfo.PrizeId,
            }).then(r=>{
              this.$toast(r.Message);
              if(r.Result==1){
                this.pageInit();
              }
            })
          }else{
            this.getPrize(this.prizeInfo.PrizeId);
          }
        }else{
          this.$toast(r.Message);
        }
      })
    },
    share(){
      if(!this.isAPP){
        this.openAPP();
        return;
      }
      if(!this.userId){
        this.callLogin();
        return;
      }
      bridge.call('userShare',`{
        "shareUrl":"${window.location.origin}/#/inviteRegister?userId=${this.userId}&userType=${this.userType}",
        "shareTitle":"千万工单海量好礼等你来拿，工程师速来！",
        "shareDesc":"全国性、跨区域的配电及自动化工业服务平台",
        "shareIcon":"https://www.insupai.com/bpojob/supai.ico",
        "isShareWxSmallProgram":false
      }`);
    }
  },
  destroyed() {
    clearTimeout(this.timer);
  },
}
</script>
<style lang="scss">
@import '~@/style/base.scss';
.prizeDetail{
  width: 10rem;
  min-height: 100vh;
  margin: 0 auto; 
  position: relative;
  overflow: hidden;
  @include bg('~static/h5/invite/sec_newbg1.png');
  background-size: contain;
  background-position: center top;
  background-color: #002354;
  .top-slider{
    position: absolute;
    left: 50%;
    top: px2rem(30px);
    transform: translateX(-50%);
    color: #fff;
    width: px2rem(520px);
    height: px2rem(64px);
    text-align: center;
    font-size: 14px;
    line-height: px2rem(64px);
    white-space: nowrap;
    overflow: hidden;
  }
  .wx-mask{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    @include bg('~static/h5/invite/cover.png');
    background-size: cover;
  }
  .btn-fixed{
    position: absolute;
    right: px2rem(15px);
    top: px2rem(125px);
    width: px2rem(178px);
    height: px2rem(113px);
    @include bg('~static/h5/invite/btn_gift.png');
    background-size: cover;
  }
  .aside{
    position: absolute;
    right: px2rem(54px);
    top: px2rem(387px);
    text-align: right;
    .btn-mini{
      width: px2rem(123px);
      height: px2rem(42px);
      @include bg('~static/h5/invite/btn_mini.png');
      background-size: contain;
      text-align: center;
      font-size: 12px;
      line-height: px2rem(42px);
      color: #fff;
      background-color: transparent;
    }
    .tip{
      font-size: 14px;
      color: #fff;
      line-height: 24px;
      margin-top: 8px;
    }
  }
  .sec2{
    width: px2rem(744px);
    height: px2rem(376px);
    @include bg('~static/h5/invite/sec_bg2.png');
    background-size: contain;
    padding: px2rem(228px) px2rem(134px) 0;
    display: flex;
    justify-content: space-between;
    margin: px2rem(666px) auto 0;
    .btn-mini{
      width: px2rem(123px);
      height: px2rem(42px);
      @include bg('~static/h5/invite/btn_mini.png');
      background-size: contain;
      text-align: center;
      font-size: 12px;
      line-height: px2rem(42px);
      color: #fff;
      background-color: transparent;
    }
  }
  .sec3{
    // width: px2rem(744px);
    width: px2rem(750px);
    // height: px2rem(376px);
    height: px2rem(410px);
    @include bg('~static/h5/invite/sec_newbg3.png');
    background-size: contain;
    // margin: px2rem(56px) auto 0;
    margin-top: px2rem(314px);
    // padding: px2rem(238px) px2rem(76px) 0;
    padding: px2rem(278px) px2rem(76px) 0;
    position: relative;
    .btn-mini{
      width: px2rem(268px);
      height: px2rem(74px);
      @include bg('~static/h5/invite/btn_medium.png');
      background-size: contain;
      text-align: center;
      font-size: 14px;
      line-height: px2rem(70px);
      color: #fff;
      margin: 0 auto;
    }
    .btn-text{
      position: absolute;
      right: 20px;
      top: px2rem(258px);
      font-size: 14px;
      color: #fff;
      text-decoration: underline;
      margin-left: 140px;
    }
  }
  .sec4{
    // width: px2rem(744px);
    width: px2rem(750px);
    // height: px2rem(1002px);
    height: px2rem(984px);
    @include bg('~static/h5/invite/sec_newbg4.png');
    background-size: contain;
    margin: px2rem(52px) auto 0;
    padding: px2rem(78px) px2rem(42px) 0;
    .title{
      color: #fff;
      margin-top: px2rem(20px);
      margin-bottom: px2rem(36px);
      font-size: 15px;
      font-weight: bold;
      line-height: 24px;
    }
    .list{
      height:px2rem(300px);
      overflow-y: auto;
      li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #fff;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
    .tips{
      font-size: 14px;
      color: #fff;
      line-height: 24px;
      text-align: center;
      margin-top: 6px;
    }
  }
  .tab-hd{
    width: px2rem(380px);
    height: px2rem(54px);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    background: rgba(253, 254, 246, 0.6);
    margin: 0 auto;
    .tab-hd-item{
      font-size: 13px;
      color: #fff;
      line-height: px2rem(54px);
      position: relative;
      z-index: 3;
      &.on{
        font-size: px2rem(30px);
        font-weight: bold;
        color: #3C2B94;
      }
    }
    .tab-bg{
      position: absolute;
      left: px2rem(-20px);
      top:  px2rem(-5px);
      width: px2rem(218px);
      height:  px2rem(64px);
      // background: linear-gradient(0deg, #EE2A02, #FF7E37);
      background: #ffffff;
      padding: 2px;
      border-radius: 64px;
      transition: all 0.2s linear;
      
      &.on{
        transform: translateX(px2rem(180px));
        
      }
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 64px;
        // background: linear-gradient(0deg, #d22683 , #3a2b92);
      }
    }
  }
  .btn-block{
    width: px2rem(600px);
    height: px2rem(88px);
    line-height: px2rem(88px);
    @include bg('~static/h5/invite/btn_block.png');
    background-size: contain;
    margin: px2rem(54px) auto px2rem(60px);
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-align: center;
  }
  .address-dialog{
    border-radius: 10px;
    padding:10px 20px;
    .head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .title{
        font-size: 16px;
        color: #333;
        line-height: 40px;
        font-weight: bold;
      }
    }
    .icon-close{
      color: #999;
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .form{
      width: 300px;
    }
    .form-item{
      margin-bottom: 10px;
    }
    .input{
      height: 40px;
      padding: 7px 10px;
      border: 1px solid #e5e5e5;
      width: 100%;
      font-size: 14px;
      color: #333;
      line-height: 22px;
    }
    .btn-primary{
      display: block;
      width: 100px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      background-color: #f75959;
      margin: 20px auto;
    }
  }
  .rule-dialog{
    border-radius: 10px;
    padding:10px 20px;
    width: px2rem(600px);
    .title{
      font-size: 16px;
      color: #333;
      line-height: 40px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }
    .icon-close{
      color: #999;
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .article{
      font-size: 14px;
      color: #666;
      line-height: 24px;
      max-height: 360px;
      overflow-y: auto;
      p{
        margin-bottom: 4px;
      }
      h3{
        margin-bottom: 10px;
        color: #333;
        font-weight: 500;
      }
      .tips{
        color: #f75959;
      }
    }
  }
}
.van-overflow-hidden{
  .address-dialog{
    margin-top: -100px;
  }
}
</style>